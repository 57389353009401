import { buildHierarchy, ga4BuildCategories, getGTMProductItem } from "./utils";

export const GTMPageView = (url, pageName, qs, breadcrumbs, userLoggedIn, userDevice) => {
  const item = {
    "datav3-page-url": url,
    "datav3-page-name": pageName || "",
    "datav3-page-query-string": qs || "",
    "datav3-user-loggedin": userLoggedIn ? "yes" : "no",
    "datav3-user-device": userDevice,
  };

  if (breadcrumbs && Array.isArray(breadcrumbs) && breadcrumbs.length) {
    breadcrumbs.forEach((breadcrumb, idx) => (item[`datav3-page-level-${idx + 1}`] = breadcrumb));
  }
  return {
    ...item,
  };
};

export function getGTParams(products, pageType) {
  const GTParams = {
    ecomm_pagetype: pageType,
    ecomm_prodid: [],
    ecomm_totalvalue: [],
  };
  (products || []).forEach(product => {
    const { values, variant } = product;
    let price;
    let sku;
    //Sajari records
    if (values) {
      price = values.on_discount === "true" ? values.discount_price : values.price;
      price = parseFloat(price).toFixed(2);
      sku = values.sku;
    }
    //Saleor variant
    else if (variant) {
      const _product = variant.product;
      price = _product?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount;
      if (_product.pricing.onSale) {
        price = _product?.pricing?.priceRange?.stop?.gross?.amount;
      }
      price = parseFloat(price).toFixed(2);
      sku = variant.sku;
    }
    //Saleor product
    else {
      price = product?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount;
      if (product.pricing.onSale) {
        price = product?.pricing?.priceRange?.stop?.gross?.amount;
      }
      price = parseFloat(price).toFixed(2);
      sku = product.virtualSku;
    }
    GTParams.ecomm_prodid.push(sku);
    GTParams.ecomm_totalvalue.push(price);
  });
  return GTParams;
}

const eventKey = {
  addToCart: "add",
  removeFromCart: "remove",
};
/**
 *
 * @param {Array<any>} variantProducts
 * @param {any} product
 * @param {number} quantity
 * @param {"addToCart" | "removeFromCart"} eventType
 */
export async function pushAddToCartEvents(variantProducts, product, quantity, eventType) {
  const brandAttrIndex = product?.attributes?.findIndex(attr => attr.attribute?.name === "Manufacturer");
  let brand = "";
  if (brandAttrIndex !== undefined && brandAttrIndex !== -1) {
    brand = product.attributes[brandAttrIndex].values[0]?.name;
  }
  let price = product?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount;
  if (product.pricing.onSale) {
    price = product?.pricing?.priceRange?.stop?.gross?.amount;
  }
  price = parseFloat(price).toFixed(2);
  const categories = [];
  const ancestors = product.category.ancestors?.edges?.map(edge => {
    return edge.node;
  });
  const productCategory = {
    name: product.category.name,
    level: product.category.level,
  };
  categories.push(productCategory, ...ancestors);
  const category = buildHierarchy(categories);
  const products = variantProducts.map(variantProduct => ({
    id: product.slug,
    name: product?.name,
    price,
    brand,
    category,
    quantity,
    variant: variantProduct.name,
  }));
  const dataLayer = {
    event: eventType,
    ecommerce: {
      currencyCode: "AUD",
      [eventKey[eventType]]: {
        products,
      },
    },
  };
  (await import("react-gtm-module")).default.dataLayer({ dataLayer });
  // GA4 start
  const ga4EventMap = {
    addToCart: "add_to_cart",
    removeFromCart: "remove_from_cart",
  };
  const ga4Items = variantProducts.map(variantProduct => {
    let itemId = product?.slug || "";
    let itemName = product?.name || "";
    let ga4Item = {
      item_name: itemName,
      item_id: itemId,
      price: price * 1 || 0.0,
      item_brand: brand || "",
      item_variant: variantProduct?.name || "",
      quantity: quantity * 1 || 0,
    };
    ga4BuildCategories(product, ga4Item);
    return ga4Item;
  });
  const ga4Data = {
    event: ga4EventMap[eventType],
    ecommerce: {
      items: ga4Items,
    },
  };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(ga4Data);
  console.log("==========pushAddToCartEvents===ga4Data", ga4Data);
  console.log("==========pushAddToCartEvents===window.dataLayer", window.dataLayer);
  // GA4 end
}

export async function pushOrderConfirmation(data, eventCallback) {
  if (window.google_tag_manager) {
    const { products: items, coupon, discount, total, token, shippingFee, tax, revenue } = data;
    const variants = items.map(item => ({
      ...item.variant,
      checkoutQuantity: item.quantity,
    }));

    const products = variants.map(variant => {
      const { product } = variant;
      const item = getGTMProductItem(product, variant, variant.checkoutQuantity);
      return item;
    });
    // let _total = products.reduce((accumulator, product) => {
    //   return accumulator + product.quantity * parseFloat(product.price);
    // }, 0);
    // _total = _total + parseFloat(shippingFee) + parseFloat(tax);
    const dataLayer = {
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: token,
            affiliation: "Online Store",
            revenue: revenue.toFixed(2), //Total transaction
            tax: parseFloat(tax).toFixed(2),
            shipping: parseFloat(shippingFee).toFixed(2),
            coupon,
            email_id: data?.email || "",
            phone_number: data?.phone || "",
            first_name: data?.firstName || "",
            last_name: data?.lastName || "",
            street: data?.street || "",
            city: data?.city || "",
            region: data?.region || "",
            country: data?.country || "",
            postal_code: data?.postalCode || "",
          },
          products,
        },
      },
      eventCallback,
      eventTimeout: "2000",
    };
    (await import("react-gtm-module")).default.dataLayer({ dataLayer });
    // GA4 start
    const ga4 = true;
    const ga4Items = variants.map(variant => {
      const { product } = variant;
      const ga4Item = getGTMProductItem(product, variant, variant.checkoutQuantity, ga4);
      return ga4Item;
    });
    const ga4Data = {
      event: "order_confirmation",
      ecommerce: {
        transaction_id: token?.replaceAll("-", "")?.slice(0, 20) || "",
        affiliation: "Online Store",
        value: revenue.toFixed(2) * 1 || 0.0,
        tax: parseFloat(tax).toFixed(2) * 1 || 0.0,
        shipping: parseFloat(shippingFee).toFixed(2) * 1 || 0.0,
        currency: "AUD",
        discount: discount?.amount * 1 || 0.0,
        coupon: coupon || "",
        email_id: data?.email || "",
        phone_number: data?.phone || "",
        first_name: data?.firstName || "",
        last_name: data?.lastName || "",
        street: data?.street || "",
        city: data?.city || "",
        region: data?.region || "",
        country: data?.country || "",
        postal_code: data?.postalCode || "",
        items: ga4Items,
      },
    };
    window.dataLayer.push(ga4Data);
    console.log("==========pushOrderConfirmation===event", "purchase");
    console.log("==========pushOrderConfirmation===ga4Data", ga4Data);
    console.log("==========pushOrderConfirmation===window.dataLayer", window.dataLayer);
    // GA4 end
  } else {
    console.warn("GTM is not loaded!");
    eventCallback();
  }
}

export const gaPurchaseEvent = (total, transId) => {
  gtag("event", "conversion", {
    send_to: process.env.NEXT_PUBLIC_GA_CONVERSION,
    value: parseFloat(total).toFixed(2),
    currency: "AUD",
    transaction_id: transId,
  });
};

/**
 *
 * @param {{
 * values:any
 * }} product Typesense product record
 * @param {number} position Position of product
 * @param {string} listName Product list name
 */
export async function pushOnClickCatalogList(product, position, listName) {
  const categories = product.categories.map((category, level) => ({
    name: category,
    level,
  }));
  const hierarchy = buildHierarchy(categories);
  const price = product.on_discount === "true" ? product.discount_price : product.price;
  const dataLayer = {
    event: "productClick",
    ecommerce: {
      currencyCode: "AUD",
      click: {
        actionField: { list: listName, action: "click" },
        products: [
          {
            id: product.sku, //SKU
            name: product.title, //Product Name
            price, //Product Price
            brand: product.brand,
            category: hierarchy,
            position,
          },
        ],
      },
    },
  };
  (await import("react-gtm-module")).default.dataLayer({ dataLayer });
  // GA4 start
  let item_list = listName;
  let ga4Item = {
    item_name: product?.title || "",
    item_id: product?.url_key || "",
    price: price * 1 || 0.0,
    item_brand: product?.brand || "",
    item_variant: "",
    item_list_name: item_list || "",
    item_list_id: item_list || "",
    index: position * 1 || 0,
  };
  const sajari = true;
  ga4BuildCategories(product, ga4Item, sajari);
  const ga4Data = {
    event: "select_item",
    ecommerce: {
      items: [ga4Item],
    },
  };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(ga4Data);
  console.log("==========pushOnClickCatalogList===ga4Data", ga4Data);
  console.log("==========pushOnClickCatalogList===window.dataLayer", window.dataLayer);
  // GA4 end
}

export const spaGlobalTagEvent = pagePath => {
  try {
    gtag("config", `${process.env.NEXT_PUBLIC_GA_WA_PRIVATE_ID}`, {
      page_path: pagePath,
    });
  } catch (error) {
    console.error("Error in spaGlobalTagEvent", error);
  }
};
