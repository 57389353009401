export function getGTMProductItem(product, variantProduct, quantity, ga4 = false) {
  const brandAttrIndex = product?.attributes?.findIndex(attr => attr.attribute?.name === "Manufacturer");
  let brand = "";
  if (brandAttrIndex !== undefined && brandAttrIndex !== -1) {
    brand = product.attributes[brandAttrIndex].values[0]?.name;
  }
  let price = product?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount;
  if (product.pricing.onSale) {
    price = product?.pricing?.priceRange?.stop?.gross?.amount;
  }
  price = parseFloat(price).toFixed(2);
  const categories = [];
  const ancestors = product.category.ancestors?.edges?.map(edge => {
    return edge.node;
  });
  const productCategory = {
    name: product.category.name,
    level: product.category.level,
  };
  if (ancestors) {
    categories.push(productCategory, ...ancestors);
  } else {
    categories.push(retrieveParents(categories, product.category));
  }
  const category = buildHierarchy(categories);

  let ga4Item = null;
  if (ga4) {
    let itemId = product?.slug || "";
    let itemName = product?.name || "";
    ga4Item = {
      item_name: itemName,
      item_id: itemId,
      price: price * 1 || 0.0,
      item_brand: brand || "",
      item_variant: variantProduct?.name || "",
      quantity: quantity * 1 || 0,
    };
    ga4BuildCategories(product, ga4Item);
  }

  return ga4
    ? ga4Item
    : {
        id: product.slug,
        name: product?.name,
        price,
        brand,
        category,
        quantity,
        variant: variantProduct.name,
      };
}

export function ga4BuildCategories(product, target, typesense = false) {
  const categories = typesense ? product.categories : getCategoriesOf(product);
  const length = categories.length;
  categories.forEach((cat, index) => {
    let ga4CategoryKey = "item_category";
    if (index < length - 1) ga4CategoryKey = `item_category${length - index}`;
    target[ga4CategoryKey] = (typesense ? cat : cat?.name) || "";
  });
}

function getCategoriesOf(product) {
  let categories = [];
  const ancestors = product.category.ancestors?.edges?.map(edge => {
    return edge.node;
  });
  const productCategory = {
    name: product.category.name,
    level: product.category.level,
  };
  if (ancestors) {
    categories.push(productCategory, ...ancestors);
  } else {
    categories.push(retrieveParents(categories, product.category));
  }
  buildHierarchy(categories);
  return categories;
}

function retrieveParents(data, productCategory) {
  if (productCategory.parent !== null) {
    data.push(retrieveParents(data, productCategory.parent));
  }
  return {
    name: productCategory.name,
    level: productCategory.level,
  };
}

export function buildHierarchy(_data) {
  const data = _data.sort((a, b) => a.level - b.level);
  return data.map(category => category.name).join("/");
}
